<template>
<div class="mbti">
    <h1>MBTI测试</h1>
     <iframe width="100%" height="555px" frameborder="0" scrolling="no" src="https://www.apesk.com/mbti/dati28N.asp"></iframe>
</div>
</template>

<script>
export default {
name: "",
components: {},
data() {
return {

};
},
computed: {},
watch: {},
methods: {},
created(){},
mounted(){},
beforeDestroy(){}
};
</script>

<style>
.mbti{
    padding: 30px;
}
</style>